<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <!-- Search -->
          <b-col cols="6" md="6">
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                flex-wrap
              "
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
              />
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end" @click="backToYourSelf">
            <b-button variant="primary">Kendine Dön</b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProductListTable"
        class="position-relative asif_table"
        primary-key="id"
        responsive
        show-empty
        small
        hover
        bordered
        :items="filteredUsers"
        :fields="tableColumns"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(sonformultarihi)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ closeDate(data.item) }}
          </span>
        </template>
        <template #cell(pictureurl)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  'https://api.optitmr.com/User_Pictures/' +
                  data.item.pictureurl
                "
                :text="avatarText(data.item.name)"
              />
            </template>

            <div class="d-flex justify-content-between">
              <div>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.name }}
                  {{ data.item.surname }}
                </span>
                <small class="text-muted"
                  >{{ data.item.companyName.substring(0, 14) }}
                  {{ data.item.companyName.length > 20 ? "..." : "" }}
                </small>
              </div>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="useUserToken(data.item.id)"
              >
                Kullan
              </b-button>
            </div>
          </b-media>
        </template>
        <template #cell(usertype)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{
              data.item.usertype == 0
                ? "Admin"
                : data.item.usertype == 1
                ? "Şirket Yöneticisi"
                : "Kullanıcı"
            }}
          </span>
        </template>

        <template #cell(modulename)="data">
          <div
            class="d-flex justify-content-between align-items-center"
            style="min-width: 150px"
          >
            {{ data.item.modulename }}
            <b-button
              size="sm"
              variant="outline-success"
              class="d-flex"
              @click="addModule(data.item.companyid)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </template>
        <template #cell(delete)="data">
          <b-link
            class="d-flex justify-content-between border-bottom"
            @click="deleteCompany(data.item.companyid)"
          >
            <span class="mr-1">Sil</span>
            <feather-icon icon="TrashIcon" />
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} 
              </span>
            </span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredUsers.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="moduleAdd"
      ref="moduleAddModal"
      :title="'Modül Ekle - ' + userToString"
      size="md"
      :ok-title="$t('ok')"
      ok-only
      centered
      @ok="extendCompanyModule({ ...module, pricepaid: pricepaid })"
    >
      <b-form-group label="Modül:" label-for="Module" label-cols-sm="3">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-checkbox v-model="module.hasbeef">Beef</b-form-checkbox>
          <b-form-checkbox v-model="module.hasdairy">Dairy</b-form-checkbox>
          <b-form-checkbox v-model="module.hasgoat">Goat</b-form-checkbox>
          <b-form-checkbox v-model="module.hassheep">Sheep</b-form-checkbox>

          <span class="flex-grow-1 ml-2">
            Ana Ücret: {{ renewPrices.baseprice }}₺</span
          >
        </div>
      </b-form-group>
      <b-form-group
        label="Gün:"
        label-for="days"
        label-cols-sm="3"
        class="d-flex"
      >
        <div class="d-flex">
          <b-form-input id="days" v-model="module.days"></b-form-input>
          <b-form-radio-group
            v-model="module.days"
            class="ml-4"
            :options="[
              { text: 'Aylık', value: 30 },
              { text: 'Yıllık', value: 365 },
            ]"
            name="days"
          ></b-form-radio-group>
        </div>
      </b-form-group>

      <b-form-group label="Fiyat:" label-for="Price" label-cols-sm="3">
        <b-form-input id="Price" v-model="pricepaid"></b-form-input>
      </b-form-group>
      <b-form-group label="Ek Değerler">
        <b-form-group label="Formula:" label-for="formula" label-cols-sm="3">
          <div class="d-flex">
            <b-form-spinbutton
              id="formula"
              v-model="module.maxformula"
              min="10"
              step="10"
            ></b-form-spinbutton>
            <span class="flex-grow-1 ml-2">
              Formül Ücreti: {{ renewPrices.formulaprice }}₺
            </span>
          </div>
        </b-form-group>
        <b-form-group label="İşletme:" label-for="plant" label-cols-sm="3">
          <div class="d-flex">
            <b-form-spinbutton v-model="module.maxplant"></b-form-spinbutton>
            <span class="flex-grow-1 ml-2">
              İşletme Ücreti: {{ renewPrices.plantprice }}₺
            </span>
          </div>
        </b-form-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
  BFormGroup,
  BFormCheckbox,
  BFormRadioGroup,
  BFormSpinbutton,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useAsIfList from "./useAsIfList";
import { onMounted } from "@vue/composition-api";

import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { debounce } from "@/libs/helpers";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BFormSpinbutton,
  },
  data() {
    return {
      module: {
        companyid: 0,
        days: 30,
        maxformula: 10,
        maxplant: 1,
        hasdairy: false,
        hasbeef: false,
        hassheep: false,
        hasgoat: false,
      },
      pricepaid: 100,

      renewPrices: {},
    };
  },

  computed: {
    userToString() {
      var user = this.filteredUsers.find(
        (x) => x.companyid == this.module.companyid
      );

      if (user && user.name && user.surname)
        return `${user.name} ${user.surname} - ${user.email} `;
      else return "";
    },
  },
  watch: {
    module: {
      handler: function (val, oldVal) {
        this.getRenewPrice(val, this);
      },
      deep: true,
    },
  },
  methods: {
    closeDate({
      sonformultarihibeef,
      sonformultarihidairy,
      sonformultarihigoat,
      sonformultarihisheep,
    }) {
      const now = dayjs();
      const dates = [
        sonformultarihibeef,
        sonformultarihidairy,
        sonformultarihigoat,
        sonformultarihisheep,
      ];

      const closest = dates.reduce((a, b) => {
        const aDiff = Math.abs(now.diff(a));
        const bDiff = Math.abs(now.diff(b));

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });

      return dayjs(closest).locale("tr").format("DD MMMM YYYY HH:mm:ss");
    },
    getRenewPrice: debounce(async function (val, that) {
      var user = that.filteredUsers.find(
        (x) => x.companyid == that.module.companyid
      );
      that.renewPrices = await that.$store.dispatch(
        "asIfModule/getRenewPrice",
        {
          userid: user.id,
          priceType: val.days < 365 ? 2 : 1,
          maxformula: val.maxformula,
          maxplant: val.maxplant,
          hasdairy: val.hasdairy,
          hasbeef: val.hasbeef,
          hassheep: val.hassheep,
          hasgoat: val.hasgoat,
        }
      );

      that.pricepaid = 0;
      if (that.module.hasdairy)
        that.pricepaid += that.renewPrices.basepricedairy;
      if (that.module.hasbeef) that.pricepaid += that.renewPrices.basepricebeef;
      if (that.module.hassheep)
        that.pricepaid += that.renewPrices.basepricesheep;
      if (that.module.hasgoat) that.pricepaid += that.renewPrices.basepricegoat;
      that.pricepaid += that.renewPrices.formulaprice;
      that.pricepaid += that.renewPrices.plantprice;
    }, 500),

    async addModule(companyid) {
      var user = this.filteredUsers.find((x) => x.companyid == companyid);

      this.module.moduleid = user?.modulename == "Pro" ? 1 : 2;
      this.module.companyid = companyid;
      this.module.maxplant = user.maxplant;
      this.module.maxformula = user.maxformula;
      this.$refs.moduleAddModal.show();
    },
    async deleteCompany(companyid) {
      await this.$swal({
        title: this.$t("yourCompanyDeletePermenantly"),
        text: this.$t("areYouSure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          var result = await this.$http.delete(
            `/Companies/Delete/${companyid}`
          );
          // // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          if (result.statusdata != "ERROR") {
            // Redirect to login page
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("deleted", { type: this.$t("company") }),
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("notDeleted", { type: this.$t("company") }),
              },
            });
          }
        } else {
          return;
        }
      });

      this.fetchUsers();
    },
  },
  setup({ emit }) {
    const {
      allUsers,
      filteredUsers,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      extendCompanyModule,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,
    } = useAsIfList();

    const useUserToken = async (id) => {
      var user = await store.dispatch("asIfModule/getAsIf", id);
      var me = JSON.parse(localStorage.getItem("userData"));
      const newAbility = [
        {
          action: "read",
          subject: "Auth",
        },
        {
          action: "read",
          subject: "Dairy",
        },
        {
          action: "read",
          subject: "Beef",
        },
        {
          action: "read",
          subject: "Sheep",
        },
        {
          action: "read",
          subject: "Goat",
        },
        {
          action: "read",
          subject: "CompanyAdmin",
        },
        {
          action: "read",
          subject: "Admin",
        },
      ];

      user.data.ability = newAbility;
      localStorage.setItem("userDataAsIf", JSON.stringify(me));
      localStorage.setItem("userData", JSON.stringify(user.data));
      localStorage.setItem("token", user.data.token);
      window.location.reload();
    };

    const backToYourSelf = () => {
      var me = JSON.parse(localStorage.getItem("userDataAsIf"));
      localStorage.setItem("userData", JSON.stringify(me));
      localStorage.setItem("token", me.token);
      localStorage.removeItem("userDataAsIf");
      window.location.reload();
    };

    const useFirstPlant = async () => {
      var plants = await store.dispatch("plantsModule/fetchPlants");
      if (!plants.includes((x) => x.id === localStorage.getItem("plantid"))) {
        localStorage.setItem("plantid", plants[0].id);
        store.commit("app/UPDATE_PLANT", plants[0].id);
      }
    };

    onMounted(async () => {
      useFirstPlant();
      await fetchUsers();
    });

    return {
      allUsers,
      filteredUsers,
      // Sidebar
      backToYourSelf,
      useUserToken,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      extendCompanyModule,
      // Extra Filters
      typeFilter,
      genderFilter,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
.asif_table {
  font-size: 12px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.w200 {
  width: 200px;
  min-width: 200px;
}
</style>
