import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();

  const refProductListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "pictureurl", label: "Kullanıcı" },
    { key: "mobile", label: "Telefon" },
    { key: "email", label: "Email" },
    { key: "usertype", label: "Kullanıcı Tipi" },
    {
      key: "sonformultarihi",
      label: "Son Aktivite",
      tdClass: "text-center w200",
      tdStyle: { width: "200px" },
    },
    {
      key: "expiredate",
      label: "Paket Sona Erme Tarihi",
      sortable: true,
      formatter: (val) =>
        `${dayjs(val).locale(VueI18n.locale).format("DD MMMM YYYY HH:mm:ss")}`,
      tdClass: "text-center w200",
      tdStyle: { width: "200px" },
    },
    { key: "modulename", label: "Modül Adı", sortable: true },
    { key: "maxformula", label: "Formül Limiti" },
    { key: "maxplant", label: "İşletme Limiti" },

    { key: "delete", label: VueI18n.t("delete"), sortable: false },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allUsers = ref([]);
  const filteredUsers = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredUsers.value.length;

    const moreThanPage =
      perPage.value * (currentPage.value - 1) < localItemsCount - perPage.value;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
        (moreThanPage
          ? perPage.value
          : localItemsCount - perPage.value * (currentPage.value - 1)),
      of: allUsers.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value)
      filteredUsers.value = allUsers.value.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.surname
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          item.mobile.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.email.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.companyName
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        );
      });
    else filteredUsers.value = allUsers.value;
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchUsers = async (ctx, callback) => {
    var users = await store.dispatch("asIfModule/fetchUsers").catch((e) => {
      toast({
        component: ToastificationContent,
        props: {
          title: VueI18n.t("xFetchError", {
            value: VueI18n.t("companyProducts"),
          }),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    allUsers.value = users;
    filteredUsers.value = [...users];
    refetchData();
    return users;
  };

  const extendCompanyModule = async (ctx, callback) => {
    await store.dispatch("asIfModule/extendCompanyModule", ctx).catch((e) => {
      toast({
        component: ToastificationContent,
        props: {
          title: VueI18n.t("xFetchError", {
            value: VueI18n.t("companyProducts"),
          }),
          text: e.response.data.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    fetchUsers();
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    allUsers,
    extendCompanyModule,
    filteredUsers,
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
